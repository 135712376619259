import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/index'
import { Container, Row, Col } from 'reactstrap'
import { Box, SupportFeedback } from './home.styled'
import { FaqFragment } from '../fragments/faq'
import Articles from '../components/articles'
import Image from "../components/image"

class SupportHomeTemplate extends React.PureComponent<{data}> {

  public render() {

    const { indexPage, topFaqs, groupmeBasics, gettingStarted, groupmeSettings,
     troubleshooting, managingGroups, managingNotifications, sharing, coolFeatures, 
     calendarEvents, sms } = this.props.data
    const { frontmatter, html } = indexPage
    const { langKey } = this.props.pageContext;

    return (
      <Layout>

        <section  style={{ backgroundColor: '#f5f5f5', paddingTop:50}}> 
            <Container>
                <Row>
                    <Col>
                        <h1>SPORT BUDDY</h1>
                        <p>CHYTRÁ SPORTOVNÍ APLIKACE</p>

                        <p>Vše přehledně, jednoduše a na jednom místě. Vždy po ruce.</p>
                    </Col>
                    <Col>
                        <Image />
                    </Col>
                </Row>
            </Container>
        </section>

        <svg style={{ backgroundColor: '#FFF'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10">
            <path style={{fill: '#f5f5f5'}} d="m 0 0 c 20 10 50 10 100 0 z"></path>
        </svg>
        
        <Container>
          <Row>
            <Col>
              <Box>
                <h2>Trending topics AA</h2>
                <Articles data={groupmeBasics} />
              </Box>
            </Col>

            <Col>
              <Box>
                <h2>Quick links</h2>
                <ul>
                  <li><a href="#">Reset password</a></li>
                  <li><a href="#">Download GroupMe</a></li>
                  <li><a href="#">Create a Poll</a></li>
                </ul>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col>
              <SupportFeedback>
                <h2>Support feedback</h2>
                <p><a href="#">Help us improve GroupMe support by sharing your ideas in a quick survey</a></p>
              </SupportFeedback>
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>GroupMe basics</h3>
              <Articles data={groupmeBasics} />
            </Col>
            <Col>
              <h3>Getting started</h3>
              <Articles data={gettingStarted} />
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>GroupMe Settings</h3>
              <Articles data={groupmeSettings} />
            </Col>
            <Col>
              <h3>Troubleshooting</h3>
              <Articles data={troubleshooting} />
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Managing groups</h3>
              <Articles data={managingGroups} />
            </Col>
            <Col>
              <h3>Managing notifications</h3>
              <Articles data={managingNotifications} />
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Sharing files, photos and more...</h3>
              <Articles data={sharing} />
            </Col>
            <Col>
              <h3>Cool features</h3>
              <Articles data={coolFeatures} />
            </Col>
          </Row>

          <Row>
            <Col>
              <h3>Calendar events</h3>
              <Articles data={calendarEvents} />
            </Col>
            <Col>
              <h3>SMS users</h3>
              <Articles data={sms} />
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default SupportHomeTemplate

export const pageQuery = graphql`
  query($path: String!) {

    indexPage:markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...FaqFragment
    },

    topFaqs: allMarkdownRemark(
      filter: {
        frontmatter: { 
          templateKey: { eq: "faq" }, 
          langKey: { eq: "en" }, 
          categories: { in: "top-faq" }
        }
      }
    ){
        totalCount
        edges {
          node {
            ...FaqFragment
          }
        }
      },

    groupmeBasics: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "groupme-basics" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    gettingStarted: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "getting-started" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    groupmeSettings: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "groupme-settings" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    troubleshooting: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "troubleshooting" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    managingGroups: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "managing-groups" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    managingNotifications: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "managing-notifications" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    sharing: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "sharing" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    coolFeatures: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "cool-features" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    calendarEvents: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "calendar-events" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

    sms: allMarkdownRemark(
      filter: { 
        frontmatter: { 
          templateKey: { eq: "faq" },
          langKey: { eq: "en" },
          categories: { in: "sms" }
        }
      }
    ){
      totalCount
      edges {
        node {
          ...FaqFragment
        }
      }
    },

  }
`
import React from 'react'
import { Link } from 'gatsby'

export default class Articles extends React.Component {
  public render(){

    const data = this.props.data;

    return(<div>
      {data && <ul>
        {data.edges.slice(0, 6).map(({ node }) => (
          <li key={node.id}>
            <Link to={node.frontmatter.path}>
              {node.frontmatter.title}
            </Link>
          </li>
        ))}
      </ul>}

      {data &&  data.totalCount > 6 && <Link to="#">
          <small>See all {data.totalCount} articles</small>
        </Link>}

      {!data && <div>There are no articles in this category</div>}

    </div>)
  }
}
import styled from 'styled-components'

export const Box = styled.div`
	border: 1px solid #ddd;
	border-radius: 10px;
	padding:45px 60px;
	min-height: 250px;
	margin-bottom:20px;
`

export const SupportFeedback = styled.div`
	padding:45px 60px;
	text-align: center;
	margin-bottom:20px;
`